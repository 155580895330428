import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { SmsActivity } from "@/models/activity";
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { parseTime, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Vue, Watch } from "vue-property-decorator";
import { h } from "vue";
import ActiveDetail from "./detail";
import './index.scss'

export default class Active extends Vue {
  public type = ''
  public title = ''
  public total = 0
  public listSum = 0
  public searchObj: any = {}
  public articleList: any[] = []


  public detailRow: SmsActivity = {}
  public memberLevelList : Array<OptionsRow> = []

  public statusList: Array<OptionsRow> = [
    { name: '未开启', value: 0 },
    { name: '报名中', value: 1 },
    { name: '已开始', value: 2 },
    { name: '已结束', value: 3 }
  ]

  private searchList: Array<SearchListRow> = []
  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'activeAdd' }
  ]

  public identityList: Array<OptionsRow> = []
  public homeactiveList: Array<OptionsRow> = []

  private tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '时间', slot: {
      default: (scope: any): JSX.Element => <div>
        <span>{ parseTime(new Date(scope.row.signUpStartTime).getTime(), '{y}-{m}-{d}') }</span>
        <br />
        <span>{ parseTime(new Date(scope.row.signUpEndTime).getTime(), '{y}-{m}-{d}') }</span>
      </div>
    } },
    { label: '名称', prop: 'title' },
    { label: '驻场艺术家', slot: {
      default: (scope: any): JSX.Element => <span>{ this.articleList.find(item => item.value === scope.row.articleId)?.name }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any): JSX.Element => <span class={['actives-status', `actives-status-${scope.row.status}`]}>{ this.statusList.find(item => item.value === scope.row.status)?.name }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class={['span-button-primary', scope.row.loading && 'el-icon-loading']} onClick={ () => this.activeEdit(scope.row, scope.$index) }>{ !scope.row.loading && '详情' }</span>
        <span class="span-button-danger" onClick={ () => this.activeDelete(scope.row.id) }>删除</span>
      </div>
    } },
  ]

  @Watch('type')
  titleChange(val?: string): void {
    this.title = typeWatch(val)
    switch(val){
      case 'add':
        break
      case 'edit':
        this.detailEdit()
        break
      default:
        this.getList()
    }
  }

  detailEdit(): void {
    this.$nextTick(() => {
      const __activeDetail = this.$refs.activeDetail as ActiveDetail
      __activeDetail.activeForm = this.detailRow
    })
  }

  activeAdd(): void {
    this.type = 'add'
    this.tableData = []
  }

  activeEdit(row: SmsActivity, index: number): void {
    if(this.tableData[index].loading) {
      return
    }
    const obj = Object.assign({}, row) as SmsActivity
    obj.articleIntroduce = this.articleList.find(item => obj.articleId === item.id)?.introduce
    this.tableData[index].loading = true
    api.selectGetMonthById(obj.monthId as number).then(res => {
      obj.signUpTime = [obj.signUpStartTime as Date, obj.signUpEndTime as Date]
      obj.monthId = [`jinx_${res.data.year}`, obj.monthId as number]
      obj.identityList = obj.identity?.split(',').map(item => +item)
      this.detailRow = obj
      this.type = 'edit'
      this.tableData[index].loading = false
      this.tableData = []
    }).catch(() => this.tableData[index].loading = false)
  }

  activeDelete(id: number): void {
    ElMessageBox.confirm('确定删除该活动？', '删除活动', {
      type: 'warning'
    }).then(() => {
      api.activeActivityDeleteActivity([id]).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除活动成功'
        })
        this.listSum++
      })
    }).catch(() => {
      return false
    })
  }

  @Watch('listSum')
  listSumChange(sum: number): void {
    if(sum) {
      this.getList()
    }
  }

  buttonClick(click: string): void {
    this[click]()
  }

  onSearch(row: never): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  getList(): void {
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
      api.activeActivityGetActivityList({ pageNum, pageSize, ...this.searchObj }).then(res => {
        this.tableData = res.data.list.map(item => ({ ...item, loading: false }))
        this.total = res.data.total
      })
    })
  }

  getData(): void {
    Promise.all([
      api.activeArticleGetArticleListSelect(),
      api.memberLevelList()
    ]).then(res => {
      this.articleList = res[0].data.map(item => ({ name: item.title, value: item.id }))
      this.memberLevelList = res[1].data.map(item => ({ name: item.name, value: item.id }))
      this.searchList = [
        { label: '活动名称', type: 'input', placeholder: '请输入活动名称', submitKey: 'title' },
        { label: '驻场艺术家', icon: 'svg_2', type: 'select', options: this.articleList, placeholder: '请输入驻场艺术家名称', submitKey: 'articleId' },
        { label: '活动状态', type: 'select', options: this.statusList, placeholder: '请输入活动名称', submitKey: 'status' },
        { label: '活动时间', icon: 'svg_3', type: 'time', submitKey: ['activityStartTime', 'activityEndTime'] }
      ]
    })
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return this.type
      ? h(ActiveDetail, { title: this.title, ref: 'activeDetail' })
      : <div class="actives">
        <Card title="活动查询" class="actives-card">
          <Search list={ this.searchList } class="actives-search" onSearch={ this.onSearch } />
        </Card>
        <Card title="活动管理">
          <div class="actives-search">
            <Button list={ this.buttonList } onClick={ this.buttonClick } />
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
          </div>
        </Card>
      </div>
  }

  mounted(): void {
    this.getList()
  }
}
