import api from "@/api";
import Card from "@/components/Card";
import ImageCell, { FormRule } from "@/components/ImageCell";
import ArticleInfo from "@/components/ArticleInfo";
import SvgIcon from "@/components/SvgIcon";
import { SmsActivity } from "@/models/activity";
import { SysMonth } from "@/models/select";
import { debounceClick } from "@/utils";
import { ElNotification } from "element-plus";
import { Vue, Prop, Options } from "vue-property-decorator";
import { h } from "vue";
import Active from ".";

@Options({})
export default class ActiveDetail extends Vue {
  @Prop(String) readonly title!: string

  public activeForm: SmsActivity = {
    title: '',
    identity: '',
    identityList: [],
    peopleNumber: 0,
    position: '',
    introduce: '',
    activityTime: '',
    point: '',
    price: 0,
    signUpTime: [],
    mainImg: '',
    coverImg: '',
    otherImg: '',
    articleId: undefined,
    articleIntroduce: '',
    articleImg: '',
    monthId: []
  }
  private formRules: any = {
    activityTime: [
      { required: true, message: '请选择活动时间', trigger: 'change' }
    ],
    signUpTime: [
      { required: true, message: '请选择报名时间', trigger: 'change' }
    ],
    monthId: [
      { required: true, message: '请选择月份', trigger: 'change' }
    ]
  }

  public monthList: Array<SysMonth> = []

  private monthProps: any = {}

  public mainImgBool = false
  public coverImgBool = false
  public otherImgBool = false
  public articleImgBool = false

  private url = ''
  private srcList: Array<string> = []

  goBack(): void {
    const __parent = this.$parent as Active
    __parent.type = ''
    this.activeForm = {
      title: '',
      identity: '',
      identityList: [],
      peopleNumber: 0,
      position: '',
      introduce: '',
      activityTime: '',
      point: '',
      price: 0,
      signUpTime: [],
      mainImg: '',
      coverImg: '',
      otherImg: '',
      articleId: undefined,
      articleIntroduce: '',
      articleImg: '',
      monthId: []
    }
  }

  onChangeImg(form: FormRule): void {
    this.activeForm.coverImg = form.coverImg
    this.activeForm.mainImg = form.mainImg
    this.activeForm.otherImg = form.otherImg
  }

  detailDom(): JSX.Element {
    const __parent = this.$parent as Active

    return <div class="actives-detail">
      <el-row gutter={ 50 }>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="actives-detail-cell">
          <el-form-item label="活动名称" prop="title">
            <el-input v-model={ this.activeForm.title } clearable placeholder="请输入活动名称" />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="actives-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_4" />
              <span>活动身份</span>
            </>}}
            prop="identityList"
          >
            <el-select
              v-model={ this.activeForm.identityList }
              clearable
              multiple
              collapse-tags
              placeholder="请选择活动身份"
              filterable
              style="width: 100%"
            >
              {__parent.memberLevelList.map(item => (
                <el-option label={ item.name } value={ item.value } />
              ))}
            </el-select>
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="actives-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_5" />
              <span>报名人数</span>
            </>}}
            prop="peopleNumber"
          >
            <el-input-number type="number" v-model={ this.activeForm.peopleNumber } clearable placeholder="请输入报名人数" style="width: 100%" min={ 0 } />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } sm= { 24 } class="actives-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_7" />
              <span>活动地点</span>
            </>}}
            prop="position"
          >
            <el-input v-model={ this.activeForm.position } clearable placeholder="请输入活动地点" />
          </el-form-item>
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_3" />
              <span>活动时间</span>
            </>}}
            prop="activityTime"
          >
            <el-date-picker
              v-model={ this.activeForm.activityTime }
              type="date"
              placeholder="年/月/日"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col lg={ 16 } sm= { 24 } class="actives-detail-cell">
          <el-form-item prop="introduce" label="活动综述" >
            <el-input type="textarea" v-model={ this.activeForm.introduce } clearable style="width: 100%" maxlength={400} show-word-limit />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm= { 24 } class="actives-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_3" />
              <span>报名时间</span>
            </>}}
            prop="signUpTime"
          >
            <el-date-picker
              v-model={ this.activeForm.signUpTime }
              type="daterange"
              range-separator="-"
              start-placeholder="年/月/日"
              end-placeholder="年/月/日"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm= { 24 } class="actives-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_8" />
              <span>活动积分</span>
            </>}}
            prop="point"
          >
            <el-input v-model={ this.activeForm.point } clearable placeholder="请输入活动积分" />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm= { 24 } class="actives-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_9" />
              <span>活动价格</span>
            </>}}
            prop="price"
          >
            <el-input-number v-model={ this.activeForm.price } clearable placeholder="请输入活动价格" style="width: 100%" min={ 0 } />
          </el-form-item>
        </el-col>
      </el-row>
      {h(ImageCell, {
        ref: 'image_cell',
        coverImg: this.activeForm?.coverImg,
        mainImg: this.activeForm?.mainImg,
        otherImg: this.activeForm?.otherImg,
        sizeType: 'activity',
        onChangeImg: this.onChangeImg
      })}
      <div class="actives-detail-submit" onClick={() => debounceClick(this.submit)}>保存</div>
      <div class="actives-detail-top">
        <el-form-item label="月份" prop="monthId" >
          <el-cascader v-model={ this.activeForm.monthId } props={ this.monthProps } style="width:100%" />
        </el-form-item>
      </div>
    </div>
  }

  submit(): void {
    const __form = this.$refs.form as any
    const __parent = this.$parent as Active

    const __imageCell = this.$refs.image_cell as ImageCell
    const __articleInfo = this.$refs.article_info as ArticleInfo

    __form.validate(v => {
      if (v) {
        const form = Object.assign({}, this.activeForm, __articleInfo.form, __imageCell.form)
        form.signUpStartTime = form.signUpTime?.length ? form.signUpTime[0] : undefined
        form.signUpEndTime = form.signUpTime?.length ? form.signUpTime[1] : undefined
        form.monthId = form.monthId && form.monthId[1]
        form.identity = form.identityList?.join(',')
        delete form.identityList

        delete form.signUpTime
        __parent.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SmsActivity): void {
    delete form.id
    api.activeActivityAddActivity(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加活动成功'
      })
      this.goBack()
    })
  }

  submitEdit(form: SmsActivity): void {
    api.activeActivityEditActivity(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑活动成功'
      })
      this.goBack()
    })
  }

  getMonthList(): void {
    this.monthProps = {
      lazy: true,
      lazyLoad: (node, resolve) => {
        const { level } = node
        if(level > 0) {
          api.selectGetMonth({ year: node.data.label }).then(res => {
            this.monthList = res.data
            const nodes = res.data.map(item => ({
              value: item.id,
              label: item.fullName,
              leaf: level >= 1
            }))
            resolve(nodes)
          })
        } else {
          api.selectGetYear().then(res => {
            const nodes = res.data.map(item => ({
              value: `jinx_${item}`,
              label: item,
              leaf: level >= 1
            }))
            resolve(nodes)
          })
        }
      }
    }
  }

  created(): void {
    this.getMonthList()
  }

  render(): JSX.Element {
    return <el-form
      ref="form"
      model={ this.activeForm }
      rules={ this.formRules }
      class="actives"
    >
      <Card title={ `${ this.title }活动信息` } back onGoBack={ this.goBack } class="actives-card">
        {this.detailDom()}
      </Card>
      <Card title="艺术家信息">
        {h(ArticleInfo, {
          articleId: this.activeForm.articleId,
          articleImg: this.activeForm.articleImgUrl,
          articleIntroduce: this.activeForm.articleIntroduce,
          ref: 'article_info'
        })}
      </Card>
    </el-form>
  }
}
